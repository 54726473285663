import React from 'react';
import Link from 'next/link';
import Subsribe from '@src/modules/home/Subsribe';
import IntlMessages from '@crema/helpers/IntlMessages';

type FooterProps = {
  messageCta?: number;
};

const Footer = ({ messageCta = 1 }: FooterProps) => {
  return (
    <div className="hero-banner-bottom position-relative" data-aos="fade-right">
      <div className="row">
        <div className="col-lg-12">
          <div className="footer-style-three position-relative">
            <div className={'footer-top'} style={{ textAlign: 'center' }}>
              <div className="container">
                <div className="row">
                  <div className="col-xxl-8 col-xl-9 col-md-9 m-auto text-center">
                    <div className="title-style-four" data-aos="fade-up">
                      <h2 className="main-title fw-500  m0">
                        {messageCta === 1 ? (
                          <>
                            Ready to use our App? <br />
                            It’s just a matter of{' '}
                            <span className="yellow">one click</span>
                          </>
                        ) : null}
                        {messageCta === 2 ? <>Still not sure?</> : null}
                      </h2>
                    </div>
                    <p
                      className="text-xlg  mt-35 mb-70 lg-mt-30 lg-mb-50"
                      data-data-aos="fade-up"
                    >
                      Try now free of charge — Upgrade your interviewing skills.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Subsribe />

            {/* End .container */}
            <div className="bottom-footer mt-80 lg-mt-50 mb-4">
              <div style={{ margin: 'auto 5%' }}>
                {/*<div className="row">*/}
                {/*  <div className="col-lg-4">*/}
                {/*    <div className="logo d-flex justify-content-center justify-content-lg-start"></div>*/}
                {/*  </div>*/}

                {/*  <div className="col-lg-4 text-center md-mt-30 md-mb-30">*/}
                {/*    /!*<h4 className="text-white">Find us on social media</h4>*!/*/}
                {/*    /!*<Social />*!/*/}
                {/*  </div>*/}

                {/*  <div className="col-lg-4 text-center">*/}
                {/*    <h4 className="text-white">*/}
                {/*      We’re always happy to help.*/}
                {/*    </h4>*/}
                {/*    <a*/}
                {/*      href="mailto:hello@interviewsup.com"*/}
                {/*      className="mail"*/}
                {/*    >*/}
                {/*      hello@interviewsup.com*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="row">
                  <div className="col-lg-6">
                    <div className="logo d-flex text-start h6 text-white">
                      <IntlMessages
                        id="Copyright © {year} InterviewsUp.com. All rights reserved."
                        values={{ year: new Date().getFullYear() }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 text-end md-mt-30 md-mb-30 h6 text-white">
                    <Link href="/tos" className="mx-3">
                      Terms & Conditions
                    </Link>{' '}
                    <Link href="/privacy-policy" className="mx-3">
                      Privacy Policies
                    </Link>
                    {/*<Link href="/cookie-preferences" className="mx-3">*/}
                    {/*  Cookie preferences*/}
                    {/*</Link>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
